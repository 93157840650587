import React, { useState, useEffect } from "react";
import { AgCharts } from "ag-charts-react";

const GSTR1FilingChart = ({ filingData }) => {
  // Get the data dynamically from filingData for dynamic months
  const performanceData = filingData?.["GSTR3B"] || {};

  // Dynamically fetch the months from the keys in filingData
  const allMonths = Object.keys(performanceData);

  const [options, setOptions] = useState({
    data: [], // This will be populated in useEffect
    series: [
      {
        type: "line",
        xKey: "month",
        yKey: "onTime",
        yName: "Filed On Time",
        stroke: "#00b300", // Green for 'Filed On Time'
        strokeWidth: 3,
        interpolation: {
          type: 'smooth'
      },
        marker: {
          enabled: (params) => params.datum.onTime !== 0, // Hide marker if value is 0
          size: 10,
          fill: 'white',
          stroke: "#00b300",
          strokeWidth: 2,
        },
        tooltip: {
          enabled: true,
          renderer: (params) => {
            // If value is 0, don't display a tooltip
            if (params.datum[params.yKey] === 0) {
              return { content: '' }; // Return empty content instead of null
            }
            // Custom tooltip content for non-zero values
            return { content: `${params.yName}: ${params.datum[params.yKey]}th` };
          },
        },
      },
      {
        type: "line",
        xKey: "month",
        yKey: "delayed",
        yName: "Filed Delayed",
        stroke: "#ff4d4d", // Red for 'Filed Delayed'
        strokeWidth: 3,
        curve: 'natural',
        marker: {
          enabled: (params) => params.datum.delayed !== 0, // Hide marker if value is 0
          size: 10,
          fill: 'white',
          stroke: "#ff4d4d",
          strokeWidth: 2,
        },
        tooltip: {
          enabled: true,
          renderer: (params) => {
            // If value is 0, don't display a tooltip
            if (params.datum[params.yKey] === 0) {
              return { content: '' }; // Return empty content instead of null
            }
            const filingDate = params.datum[params.yKey]; // Filing date
            const dueDate = 20; // Assume due date is 20th of each month
            const delayedBy = filingDate - dueDate; // Calculate delay
        
            // Custom tooltip content for the delayed filings
            return {
              content: `${params.yName}: ${filingDate}th\nDelayed by ${delayedBy} days`, // \n will break the line
            };
          },
        },
        
      },
    ],
    axes: [
      {
        type: 'category',
        position: 'bottom',
        title: { text: 'Month' }
      },
      {
        type: 'number',
        position: 'left',
        title: { text: 'Count' },
        min: 0, // Ensures the y-axis starts at 0
      }
    ],
    legend: {
      position: 'top', // Position the legend at the top
      item: {
        marker: { size: 10 }, // Customize marker size in the legend if necessary
        paddingX: 10,  // Adjust horizontal padding between legend items
      },
    },
  });

  useEffect(() => {
    // Map allMonths dynamically to ensure a continuous x-axis
    const formattedData = allMonths.map((month) => {
      const status = performanceData[month] || "";
      const isOnTime = status.startsWith("Filed On Time");
      const count = parseInt(status.split(" - ")[1], 10) || 0; // Default to 0 if no data

      return {
        month, // e.g., "AUG-23"
        onTime: isOnTime ? count : 0, // Set Filed On Time count or 0 if missing
        delayed: !isOnTime && status ? count : 0, // Set Filed Delayed count or 0 if missing
      };
    });

    // Update chart data with the formatted data
    setOptions((prevOptions) => ({
      ...prevOptions,
      data: formattedData,
    }));
  }, [filingData]);

  return (
    <div>
      <h2 style={{ color: '#0a8394', padding: '20px 50px' }}>GSTR3B Filing Status Summary</h2>
      <AgCharts options={options} />
    </div>
  );
};

export default GSTR1FilingChart;
